import { resedCodePublish } from '../../../../service/RecendCode.js';

export const ResendCodeButton = async (mail,setIsLoading, setErrorMessage, setErrorCheck, navigate) => {
    if (mail) {
        setIsLoading(true);
        try {
            const response = await resedCodePublish(mail);
            if (response.ok) {
                const responseData = await response.json();
                navigate('/confirm' , { state: { email: mail } } )
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message);
                setErrorCheck(true);
            }
        } catch (error) {
            console.error('リクエストに失敗しました:', error);
        } finally {
            setIsLoading(false);
        }
    } else {
        const translatedMessage = 'メールアドレスが入力されていません';
        setErrorMessage(translatedMessage);
        setErrorCheck(true);
    }
};