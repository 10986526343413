import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './RegistInfoPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles } from '../../../componets/TextField.jsx'
import FormHelperText from '@mui/material/FormHelperText';
import {isKatakana, isNumeric} from '../../../utils/validation.js'
import { RegistButton } from './component/RegistButton.jsx'

function RegistInfoPage(){

  const location = useLocation();
  const { nickname } = location.state || {};
  const [family_name, setFamilyName] = useState('');
  const [given_name, setGivenName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [familyNameError, setFamilyNameError] = useState(false);
  const [givenNameError, setGivenNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //登録ボタンが押されたときにコンポーネントを呼び出す
  const registButton = async () => {
    await RegistButton(setIsLoading, setIsButtonDisabled, family_name, given_name, nickname, phone_number);
  };

  //登録ボタンの活性・非活性の変更処理
  useEffect(() => {
    //一つでも未入力があれば非活性にする
    if(family_name.length === 0 || given_name.length === 0 || phone_number.length === 0){
      setIsButtonDisabled(true); //非活性
    }
    //一つでも入力があれば、エラーチェックを実行
    else if(family_name.length > 0 || given_name.length > 0 || phone_number.length > 0){
      //一つでもエラーがある場合
      if (familyNameError || givenNameError || phoneNumberError) {
        setIsButtonDisabled(true); //非活性
      } 
      //一つもエラーがない場合
      else {
        setIsButtonDisabled(false); //活性
      }
    }
  }, [familyNameError, givenNameError, phoneNumberError, family_name.length, given_name.length, phone_number.length]);

  //入力されたフリガナ（姓）のチェック
  useEffect(() => {
    if(family_name.length > 0){
      setFamilyNameError(!isKatakana(family_name));
    }
  }, [family_name]);

  //入力されたフリガナ（名）のチェック
  useEffect(() => {
    if(given_name.length > 0){
      setGivenNameError(!isKatakana(given_name));
    }
  }, [given_name]);

  //入力された電話番号のチェック
  useEffect(() => {
    if(phone_number.length > 0){
      setPhoneNumberError(!isNumeric(phone_number));
    }
  }, [phone_number]);
  
  return (
      <div className='account-page'>
        <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
          <label style={{fontSize: '14px', marginLeft:'8%'}}>ユーザ情報登録</label>
        </div>
        <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
          <label>プロフィール情報を登録してください</label>
        </div>
        <div className='list-container-registinfo'>
          <label htmlFor="email" 
              style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                フリガナ（姓）
            </label>
            <TextField
              type="text"
              id="famiry_name"
              value={family_name}
              onChange={(e) => setFamilyName(e.target.value)}
              placeholder="ヤマダ"
              fullWidth
              error={familyNameError}
              slotProps={{
                input: {
                  sx: inputStyles
                },
              }}
            />
            {familyNameError && (
              <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                カタカナを入力してください
              </FormHelperText>
            )}
            <label htmlFor="email" 
              style={{display:'flex', justifyContent:'start', marginTop: '25px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                フリガナ（名）
            </label>
            <TextField
              type="text"
              id="given_name"
              value={given_name}
              onChange={(e) => setGivenName(e.target.value)}
              placeholder="タロウ"
              fullWidth
              error={givenNameError}
              slotProps={{
                input: {
                  sx: inputStyles
                },
              }}
            />
            {givenNameError && (
              <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                カタカナを入力してください
              </FormHelperText>
            )}
            <label htmlFor="email" 
              style={{display:'flex', justifyContent:'start', marginTop: '25px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                電話番号
            </label>
            <TextField
              type="tel"
              id="phone_number"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="ハイフン無し（09012345678)"
              fullWidth
              error={phoneNumberError}
              slotProps={{
                input: {
                  sx: inputStyles
                },
              }}
            />
            {phoneNumberError && (
              <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                数字のみ入力してください
              </FormHelperText>
            )}
            <Button className="loginButton" onClick={registButton} style={{marginTop: '30px', marginBottom: '30px'}} disabled={isButtonDisabled}>登録する</Button>
          </div>
        {isLoading && (
          <div className="loading-overlay">
            <h2>ユーザー情報を登録中です。しばらくお待ちください。</h2>
            <div className="loader"></div>
          </div>
        )}
        <footer style={{zIndex: '-1'}}>
              <p>Copyright©City of Sendai All Rights Reserved.</p>
        </footer>
    </div>    
  );
};

export default RegistInfoPage;