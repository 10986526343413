//クライアントからのリクエストログイン
export const requestLogin = async () => {
    //セッションからアクセス時のクエリパラメータを取得
    const storedQueryString = sessionStorage.getItem('queryString');
    //セッションからログインしたプロバイダーの取得
    const identityProvider = sessionStorage.getItem('provider');
    //ログインするためのURLを作成
    const authUrl0 = `https://sendai-portal-auth-dev.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?` + `${storedQueryString}` + `&identity_provider=${identityProvider}`
    //クライアントに認可コードを返すためのログイン
    window.location.href = authUrl0;
};