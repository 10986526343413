import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//MaterialUIのコンポーネントインポート
import { Button } from '@mui/material';

//デザインのインポート
import { IOSSwitch } from '../../../componets/ToggleSwitch.jsx' 
import './OptinPage.css'; 

//画像のインポート
import optoinLogo from '../../../assets/optoinLogo.png';
import { OptinButton } from './component/OptinButton.jsx'


function OptoinPage({ onCancel }){
  const location = useLocation();
  const { userInfo_flag, nickname } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  //許可するかのON・OFFトグルボタンチェックフラグ
  const [checked, setChecked] = useState(false);

  //トグルボタンが押されたときのフラグチェンジメソッド
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  
  //許可ボタンが押されたときにコンポーネントを呼び出す
  const optinButton = async () => {
    await OptinButton(setIsLoading, setChecked, userInfo_flag, nickname, navigate);
  };

  return (
    <div className="login-page">
      <img src={optoinLogo} alt="Sendai" className="logo-optoin" />
      <label style={{marginTop:'10px', marginLeft:'5px', fontSize:'14px', color:'#000'}}>SENDAIポータル</label>
      <div className="login-container">
        <div className="form-container">
          <label style={{marginTop:'60px', marginBottom:'30px' ,fontSize:'14px'}}>許可が必要な項目</label>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <label style={{ fontSize: '14px',margin: '0px' }}>プロフィール情報（必須）</label>
            <IOSSwitch checked={checked} onChange={handleChange} />
          </div>

          <hr style={{marginTop:'55px', marginBottom:'35px' ,border: '0.1px solid rgba(220, 220, 220, 0.7)', width:'100%'}}></hr>

          <label style={{marginTop:'20px', fontWeight:'600', fontSize:'14px'}}>注意事項</label>
          <label style={{marginTop:'2px', marginLeft:'5px', fontSize:'14px'}}>1.～～～～</label>
          <label style={{marginTop:'2px', marginLeft:'5px', fontSize:'14px'}}>2.～～～～～</label>
          <Button className="loginButton-optoin" onClick={optinButton} disabled={!checked} style={{margin:'auto', marginTop:'40px'}}>許可する</Button>
          <Link to="/oauth2/authorize" onClick={onCancel} style={{ marginTop:'20px',color:'#000', fontWeight:'500' ,textDecoration: 'none', justifyContent: 'center', textAlig: 'center', width: '100%', fontSize:'14px' }}>
            キャンセル
          </Link>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>設定中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default OptoinPage;