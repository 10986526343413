import { CodeConfirm } from '../../../../service/CodeConfirm.js';

export const ResendCodeConfirmButton = async (setIsLoading, email, code, setErrorMessage, setErrorCheck, navigate) => {
    if (code) {
        setIsLoading(true);
        try {
            const response = await CodeConfirm(email, code);
            if (response.ok) {
              const responseData = await response.json();
              navigate('/complete');
            } else {
              const errorData = await response.json();
              setErrorMessage(errorData.message);
              setErrorCheck(true);
            }
        } catch (error) {
          console.error('Error during sign-in:', error);
        } finally {
          setIsLoading(false);
        }
    } else {
        const translatedMessage = '確認コードが入力されていません';
        setErrorMessage(translatedMessage);
        setErrorCheck(true);
     }
};