import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './views/AccountPage/AccountPage.css';

function XIDReLoginPage(){

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  //画面作成時に実行される
  useEffect(() => {
    const fetchToken = async () => {
      //URLを取得するための初期化
      const params = new URLSearchParams(location.search);
      //認可code取得
      const codeParam = params.get('code');

      if (codeParam){  //認可コードがあれば以下実行
        try {
          const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
              method: 'POST',
              headers: { 
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${codeParam}`
              },
              body: JSON.stringify({
                redirect_uri: 'https://dev-login.sendai-portal.jp/xid_relogin',
                pattern:'xid_relogin'
              }),
              credentials: 'include'
            });
          if (response.ok) {
            const responseData = await response.json();
            console.log(responseData);
          } else {
            const errorData = await response.json();
            console.error('Failed to register user:', errorData);
          }
        } catch (error) {
          console.error('Error during registration:', error);
        } finally{
          params.delete('code');
          const newUrl = `${location.pathname}?${params.toString()}`;
          window.history.replaceState(null, '', newUrl);
          setIsLoading(false);
        }
      }
    };

    //userEfect内で非同期処理を実行
    fetchToken();

  }, [location]);
  
  return (
    <div className='account-page'>
        <div>
            <h2 style={{marginTop:'80px'}}>
                接続元の画面に切り替えてください。
            </h2>
        </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>xID連携中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default XIDReLoginPage;