// 画像の配列を定義
const images = [
    'Shirokuma',
    'Kuma',
    'Kitune',
    'Saru'
];

// ランダムな画像を取得する関数
export function getRandomImage() {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
}