//オプトイン更新
export const userInfoRegist = async (family_name, given_name, nickname, phone_number) => {
    try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              pattern:'regist_info',
              userAttributes: [
                { Name: "family_name", Value: family_name},
                { Name: "given_name", Value: given_name},
                { Name: "nickname", Value: nickname},
                { Name: "custom:phone", Value: phone_number}
              ]
            }),
            credentials: 'include'
        });
        //リクエスト元に情報を返す
        return response;
    } catch (error) {
      console.error('リクエストに失敗しました:', error);
      return error;
    }
  };