import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './DeletePage.css';

export default function DeleteConfirmPage({onBackButtonClick}){

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const navigate = useNavigate();
  const navigateToError = () => {
    navigate('/error', { replace: true });
  };

  const deleteButton = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          pattern:'delete_user'
        }),
        credentials: 'include'
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('User registration successful', responseData);
        navigateToError();
      } else {
        const errorData = await response.json();
        console.error('Failed to register user:', errorData);
      }

    } catch (error) {
      console.error('Error during registration:', error);
    }finally{
      setIsLoading(false);
    }
  }

  
  return (
    <div className='account-page'>
      {!isComplete && (
        <div className='list-container-delete'>
          <p className='label' style={{marginTop:'25px'}}>一度アカウントを削除すると、アカウント情報を元に戻すことはできません。</p>
          <p className='label' style={{marginBottom:'35px'}}>本当に削除してもよろしいですか？</p>
          <Button className="change" onClick={deleteButton}>削除する</Button>
          <Button className="back" onClick={onBackButtonClick} style={{marginBottom: '35px'}}>戻る</Button>
        </div>
      )}
      {isLoading && (
        <div className="loading-overlay">
          <h2>アカウント情報を削除中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
    </div>  
  );
};