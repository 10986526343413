import { userInfoRegist } from '../../../../service/RegistUserInfo.js';
//クライアントによるリクエストログイン（アプリに戻すためのログイン）
import { requestLogin } from '../../../../service/RequestLogin.js';


export const RegistButton = async (setIsLoading, setIsButtonDisabled, family_name, given_name, nickname, phone_number) => {
  setIsLoading(true);
  setIsButtonDisabled(true);
  setTimeout( async () => {
    try {
      const response = await userInfoRegist(family_name, given_name, nickname, phone_number);
        if (response.ok) {
          const responseData = await response.json();
          console.log('ユーザ情報登録成功', responseData);
          requestLogin();
        } else {
          const errorData = await response.json();
          console.error('ユーザ登録失敗:', errorData);
          setIsLoading(false);
        }
  
    } catch (error) {
      console.error('ユーザ登録失敗:', error);
      setIsLoading(false);
    }
  }, 50);
}