export const CodeConfirm = async (email, code) => {
    try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              email:email,
              code:code,
              pattern:'code_confirm'
            }),
            credentials:'include'
        });
        return response;
    } catch (error) {
        console.error('Error during registration:', error);
        return error;
    }
  };