import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import './ResendCodePage.css'; // スタイル用のCSSファイルを作成する
import { inputStyles } from '../../../componets/TextField.jsx'
import ProgressSteps from '../../../componets/ProgressSteps.jsx';
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import {isValidEmail} from '../../../utils/validation.js'
import { ResendCodeButton } from './component/ResendCodeButton.jsx'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';

function ResendCodePage(){
  const [mail, setMail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);
  const navigate = useNavigate();

  //次へボタンが押されたときにコンポーネントを呼び出す
  const resendCodeButton = async () => {
    await ResendCodeButton(mail,setIsLoading, setErrorMessage, setErrorCheck, navigate);
  };

  //次へボタンの許可審査
  useEffect(() => {
    if(mail.length > 0){
      if (emailError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    console.log(emailError, isButtonDisabled)
  }, [emailError, mail]);

  //入力されたメールのチェック
  useEffect(() => {
    if(mail.length > 0){
      setEmailError(!isValidEmail(mail));
    }
  }, [mail]);

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={0}/>
      <div className="login-container">
        <div className="form-container">
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <label htmlFor="email" className="email">メールアドレス</label>
          <TextField
            type="email"
            id="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="例：sendai-portal@sample.com"
            fullWidth
            error={emailError}
            slotProps={{
              input: {
                sx: inputStyles
              },
            }}
          />
          {emailError && (
              <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                無効なメールアドレスです
              </FormHelperText>
            )}
          <br/>
          <Button className="loginButton" onClick={resendCodeButton} disabled={isButtonDisabled}>
            次へ
          </Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>認証コードの再発行中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ResendCodePage;