import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles, inputStylesMail } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';

function MailConfirmPage(){
    const location = useLocation();
    const { email, user_id } = location.state || {};
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(true);

    const navigate = useNavigate();
    const navigateToAccount = () => {
        setIsComplete(false);
        navigate('/account');
    };

    // フォーカスが当たったときにfooterを隠す
    const handleFocus = () => {
        setIsFooterVisible(false);
    };
    // フォーカスがはずれたときにfooterを表示
    const handleBlur = () => {
        setTimeout(() => {
        setIsFooterVisible(true);
        }, 300);
    };

    const mailEditConfirm = async () => {
        setIsLoading(true);
        console.log();
        setTimeout( async () => {
        try {
            const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              email: email,
              user_id: user_id,
              code: code,
              pattern:'edit_mail_confirm'
            }),
            credentials: 'include'
            });
    
            if (response.ok) {
            const responseData = await response.json();
            console.log('User registration successful', responseData);
            setIsComplete(true);
            } else {
            const errorData = await response.json();
            console.error('Failed to register user:', errorData);
            }
    
        } catch (error) {
            console.error('Error during registration:', error);
        } finally{
            setIsLoading(false);
        }
        }, 50);
    }

    // useEffect(() => {
    //     const fetchUserInfo = async () => {
    //     try {
    //         const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
    //         method: 'POST',
    //         headers: { 
    //             'Content-Type': 'application/json; charset=utf-8',
    //         },
    //         body: JSON.stringify({
    //             pattern:'user_info_email'
    //         }),
    //         credentials: 'include'
    //         });
    //         const data = await response.json();
    //         setUserInfo(data.Response);
    //         console.log(data);
    //     } catch (error) {
    //         console.error('Error fetching user info:', error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    //     };

    //     fetchUserInfo();
    // }, []);
  
    return (
        <div>
            <div className='account-page'>
              <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
                <label style={{fontSize: '14px', marginLeft:'8%'}}>メールアドレス変更</label>
              </div>
              {!isComplete && (
                <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>変更したメールアドレスに送信されたURLからメールの認証を行ってください。</label>
                </div>
              )}
              {isComplete && (
                <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>メールアドレスの変更が完了しました。</label>
                </div>
              )}
              {!isComplete && (
                <div className='list-container-editmail'>
                    <label htmlFor="email" 
                        style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                        現在のメールアドレス
                    </label>
                    <TextField
                        type="email"
                        id="email"
                        value={email}
                        onChange={() => {}}
                        fullWidth
                        disabled
                        slotProps={{
                            input: {
                                sx: inputStylesMail
                            },
                        }}
                    />
                    <label htmlFor="email" 
                        style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                        確認コード
                    </label>
                    <TextField
                        type="text"
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        fullWidth
                        slotProps={{
                            input: {
                                sx: inputStyles
                            },
                        }}
                        style={{marginBottom:'30px'}}
                    />
                </div>
              )}
              {!isComplete && (
                <Button className="loginButton-change" onClick={mailEditConfirm}>確認する</Button>
              )}
              {!isComplete && (
                <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '100px'}}>戻る</Button>
              )}
              {isComplete && (
                <Button className="loginButton-back" onClick={navigateToAccount} style={{marginTop: '100px'}}>戻る</Button>
              )}
              {isLoading && (
                <div className="loading-overlay">
                  <h2>メールアドレスを変更中です。しばらくお待ちください。</h2>
                  <div className="loader"></div>
                </div>
              )}
              {isFooterVisible && (
                <footer>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
                </footer> 
              )}
            </div>
        </div>
    );
};

export default MailConfirmPage;