//オプトイン更新
export const setOptoin = async (iconImage) => {
  try {
    const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        userAttributes: [
          {
              Name: "custom:optin",
              Value: "1"
          },
          {
            Name:'picture',
            Value: iconImage
          }
        ]
      }),
      credentials:'include'
    });
    return response;
  } catch (error) {
    console.error('Error during registration:', error);
    return error;
  }
};

//オプトインの確認
export const checkOptoin = async (codeParam) => {
  try{
    const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${codeParam}`
      },
      body: JSON.stringify({
        redirect_uri:'https://dev-login.sendai-portal.jp/oauth2/authorize',
        pattern:'optin_check'
      }),
      credentials: 'include'
    });
    return response;
  }catch(error){
    console.error('オプトインの取得に失敗しました。:', error);
    return error;
  }
}