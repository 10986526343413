//オプトイン更新
export const resedCodePublish = async (mail) => {
    try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              email:mail,
              pattern:'resend_code'
            }),
            credentials:'include'
        });
        return response;
    } catch (error) {
        console.error('Error during registration:', error);
        return error;
    }
  };