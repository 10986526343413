// userLogin.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkOptoin } from '../../../../service/OptinService.js';
import { requestLogin } from '../../../../service/RequestLogin.js';


export const CodeProcess = (location, setIsLoading) => {
  const navigate = useNavigate();
  const keysToCheck = ['client_id', 'redirect_uri', 'scope', 'response_type'];

  useEffect(() => {
    //関数の定義
    const fetchToken = async () => {
        //URLを取得するための初期化
        const params = new URLSearchParams(location.search);
        //認可code取得
        const codeParam = params.get('code');
        // チェックするクエリパラメータが含まれているか確認（'client_id', 'redirect_uri', 'scope', 'response_type'）
        const checkQuery = keysToCheck.some(key => params.has(key));

        //認可コードが存在する場合は以下を実行
        if (codeParam) {
            //ログイン中の画面を表示
            setIsLoading(true);
            //エラーの可能性もある処理（API利用）を実行
            try {
                //オプトインチェック（API通信発生）
                const response = await checkOptoin(codeParam);
                //API取得成功し場合に以下を実行
                if (response.ok) {
                    //レスポンスデータをjson形式にする
                    const responseData = await response.json();
                    //オプトインを許可していない場合は以下を実行
                    if (responseData.customOptin === 'null' || responseData.customOptin === '0') {
                        navigate('/optin' , { state: { userInfo_flag: responseData.infoFlag, nickname:responseData.nickname } } );
                    } 
                    //オプトインを許可している場合は以下を実行
                    else {
                        //ユーザ情報を登録済みのユーザは以下を実行
                        if (responseData.infoFlag) {
                            //再度ログインをして、接続元に認可コードを返す
                            requestLogin();
                        } 
                        //ユーザ情報を未登録のユーザは以下を実行
                        else if (!responseData.infoFlag) {
                            //ユーザ登録画面に遷移
                            navigate('/regist', { state: { nickname: responseData.nickname } });
                        }
                    }
                } 
                //API取得に失敗し場合に以下を実行
                else {
                    //エラー分をjson形式で取得
                    const errorData = await response.json();
                    //エラー文の表示
                    console.error('ユーザのログインまたは登録に失敗しました:', errorData);
                    //ログイン中の画面を表示を終わりにする
                    setIsLoading(false);
                }
            } 
            //上記try内でエラーが起こった場合には以下を実行
            catch (error) {
                //エラー文の表示
                console.error('ユーザのログインまたは登録に失敗しました:', error);
                //ログイン中の画面を表示を終わりにする
                setIsLoading(false);
            }
        } 
        //最初のアクセス時にクエリパラメータをセッションストレージに保存
        else if (checkQuery) {
            //クエリパラメータをStringで取得
            const queryString = params.toString();
            //セッションストレージに上記で取得したStringを保存
            sessionStorage.setItem('queryString', queryString);
            //クエリパラメータからlanguageのみを取得
            const language = params.get('language');
            //クエリに言語情報が設定されていた場合に以下を実行
            if (language) {
                //セッションストレージに言語情報を保存
                sessionStorage.setItem('lang', language);
            }
        }
    };
    //上記関数を実行（非同期で実行するためにこのようなやりかたとしている）
    fetchToken();
  }, [location, setIsLoading, navigate]);
};