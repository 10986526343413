import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import './ConfirmPage.css'; // スタイル用のCSSファイルを作成する
import { inputStylesMail, inputStyles } from '../../../componets/TextField.jsx'
import { Link, useNavigate } from 'react-router-dom';
import ProgressSteps from '../../../componets/ProgressSteps.jsx';
import { CodeConfirmButton } from './component/CodeConfirmButton.jsx'
import { ResendCodeLink } from './component/ResendCodeLink.jsx'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';

function ConfirmPage(){
  const location = useLocation();
  const { email } = location.state || {};
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();  

  //入力されたパスワードのチェック
  useEffect(() => {
    if(code.length > 0){
      setIsButtonDisabled(false);
    }else{
      setIsButtonDisabled(true);
    }
  }, [code]);

  //登録ボタンが押されたときにコンポーネントを呼び出す
  const confirmButton = async () => {
    await CodeConfirmButton(setIsLoading, email, setErrorMessage, setErrorCheck, navigate);
  };

  //登録ボタンが押されたときにコンポーネントを呼び出す
  const resendCodeLink = async () => {
    await ResendCodeLink(setIsLoading, email, code, setErrorMessage, setErrorCheck, navigate);
  };

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={1}/>
      <div className="login-container">
        <div className="form-container">
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <div style={{ textAlign: 'start', width: '100%' }}>
              <label style={{ display: 'block', marginTop: '30px' }}>下記メールアドレスに送信されたURLからメールの認証を行ってください</label>
          </div>
          <label htmlFor="email" className="email">送信先メールアドレス</label>
          <TextField
            type="email"
            id="email"
            value={email}
            onChange={() => {}}
            fullWidth
            disabled
            slotProps={{
              input: {
                sx: inputStylesMail
              },
            }}
          />
          <label htmlFor="password">認証コード</label>
          <TextField
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
            slotProps={{
              input: {
                sx: inputStyles
              },
            }}
          />
          <Link className='passforget' onClick={resendCodeLink}>
            認証メールを再送信する
          </Link>
          <Button className="loginButton" onClick={confirmButton} disabled={isButtonDisabled}>次へ</Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>認証情報の確認中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ConfirmPage;