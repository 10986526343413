import React, { useState, useEffect } from 'react';
import './AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles, inputStylesMail } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import {isValidEmail} from '../../utils/validation.js'

function MailEditPage(){

  const [mail, setMail] = useState('');
  const [newMail, setNewMail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const testFlag = true;

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  const navigateToNextScreen = (user_id) => {
    console.log(user_id);
    navigate('/editmailconfirm' , { state: { email: newMail, id:user_id } } );
  };

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  const mailEdit = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            mail: mail,
            new_mail: newMail,
            pattern:'edit_mail'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('User registration successful', responseData);
          navigateToNextScreen(responseData.Response.userId);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'user_info_email'
          }),
          credentials: 'include'
        });
        const data = await response.json();
        setUserInfo(data.Response);
        console.log(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setMail(userInfo.email);
    }
  }, [userInfo]);

  //入力されたメールのチェック
  useEffect(() => {
    if(newMail.length > 0){
      setEmailError(!isValidEmail(newMail));
    }
  }, [newMail]);


  useEffect(() => {
    if(newMail.length === 0){
      setIsButtonDisabled(true);
    }else if(newMail.length > 0){
      if (emailError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    console.log(emailError,isButtonDisabled)
  }, [emailError, newMail]);


  
    return (
        <div>
          {userInfo && (
            <div className='account-page'>
              <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
                <label style={{fontSize: '14px', marginLeft:'8%'}}>メールアドレス変更</label>
              </div>
              <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>変更するメールアドレスを入力してください</label>
              </div>
              <div className='list-container-editmail'>
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      現在のメールアドレス
                  </label>
                  <TextField
                    type="email"
                    id="email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled
                    fullWidth
                    slotProps={{
                      input: {
                        sx: inputStylesMail
                      },
                    }}
                  />
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      新しいメールアドレス
                  </label>
                  <TextField
                    type="email"
                    id="email2"
                    value={newMail}
                    onChange={(e) => setNewMail(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    helperText={emailError ? '無効なメールアドレスです' : ''}
                    placeholder="例：new_mail@sample.com"
                    fullWidth
                    error={emailError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                    style={{marginBottom:'30px'}}
                  />
              </div>
              <Button className="loginButton-change" onClick={mailEdit} disabled={isButtonDisabled}>変更する</Button>
              <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '100px'}}>戻る</Button>
              {isLoading && (
                <div className="loading-overlay">
                  <h2>メールアドレスを変更中です。しばらくお待ちください。</h2>
                  <div className="loader"></div>
                </div>
              )}
              {isFooterVisible && (
                <footer>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
                </footer> 
              )}
            </div>
          )}
        </div>
      
    );
};

export default MailEditPage;