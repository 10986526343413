import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { CodeProcess } from './components/CodeProcess.jsx';
import { ProviderLoginButton } from './components/SocialLogin.jsx';

//デザインのインポート
import './LoginPage.css';
//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';
import googleImage from '../../../assets/google.png';
import yahooImage from '../../../assets/yahoo.png';
import appleImage from '../../../assets/apple.png';
import sendaiportalImage from '../../../assets/sendai-app.png';

function LoginPage(){
  //ローディング画面の表示可否フラグ
  const [isLoading, setIsLoading] = useState(false);
  //クエリパラメータを取得するためのLocation初期化
  const location = useLocation();
  //Hooks関数の呼び出し（認可コードの処理部分）
  CodeProcess(location, setIsLoading);

  return (
    <div>
      <div className="login-page">
        <img src={backgroundImage} alt="Background" className="background-image" />
        <img src={sendaiImage} alt="Sendai" className="logo" />
          <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <div className="login-container">
          <div className="social-login">
            <div className="form-container">
            <Button className="loginButton-sendai" onClick={async () => await ProviderLoginButton('Cognito', setIsLoading)} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={sendaiportalImage} alt="Sendai" style={{ marginRight: '15px', width: '35px', height: '35px', padding: '5px' }} />
              SENDAIポータルでログイン
            </Button>
            <div className='account-regist'>
              <Link className='account-link' to="/resendcode">途中まで進めた登録を再開する</Link>
            </div>
            <div className="divider">
            <hr className="custom-hr"></hr>
            <div className='social'>または外部サイトのIDでログイン</div>
            <hr className="custom-hr"></hr>
          </div>
            <Button className="loginButton-social" onClick={async () => await ProviderLoginButton('Google', setIsLoading)} style={{ display: 'flex', alignItems: 'center' , marginTop: '0px'}}>
              <img src={googleImage} alt="Sendai" style={{ marginRight: '10px', width: '35px', height: '35px', padding: '5px 8px' }} />
              Googleでログイン
            </Button>
            <Button className="loginButton-social" onClick={async () => await ProviderLoginButton('Yahoo', setIsLoading)} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={yahooImage} alt="Sendai" style={{ marginRight: '15px', width: '40px', height: '20px', padding: '12.5px 0px' }} />
              Yahooでログイン
            </Button>
            <Button className="loginButton-social" onClick={async () => await ProviderLoginButton('SignInWithApple', setIsLoading)} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={appleImage} alt="Sendai" style={{ marginRight: '20px',marginLeft: '0px', width: '35px', height: '35px', padding: '5px 0px' }} />
              Appleでログイン
            </Button>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="loading-overlay">
            <h2>ログイン中です。しばらくお待ちください。</h2>
            <div className="loader"></div>
          </div>
        )}
        <footer style={{zIndex: '-1'}}>
          <p>Copyright©City of Sendai All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default LoginPage;