import React, { useState, useEffect } from 'react';
import './AccountPage.css';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ShareIcon from '@mui/icons-material/Share';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ModeIcon from '@mui/icons-material/Mode';

import backgroundImage from '../../assets/backgroun-image.png'
import footerImage from '../../assets/account_header.png';
import NickNamePage from './NickNamePage.jsx';

import Shirokuma from '../../assets/shirokuma.png'
import Kuma from '../../assets/kuma.png'
import Kitsune from '../../assets/kitsune.png'
import Osaru from '../../assets/saru.png'

function AccountPage(){
  const handleClick = (item) => {
    console.log(`Clicked on ${item}`);
  };

  const navigate = useNavigate();
  const navigateToMailEdit = () => {
    navigate('/editmail');
  };

  const navigateToPasswordEdit = () => {
    navigate('/editpassword');
  };

  const navigateToProfileEdit = () => {
    navigate('/editinfo');
  };

  const navigateToXIDAliginment = () => {
    navigate('/xidaliginment');
  };

  const navigateToDelete = () => {
    navigate('/delete');
  };

  const navigateToEditImage = () => {
    navigate('/editimage');
  };

  //ここからテキスト変更のためのコード

  const [isEditing, setIsEditing] = useState(false);
  const [nickname, setNickName] = useState('ニックネームがながくなっても？');

  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNickName, setIsNickName] = useState(false);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
      setNickName(e.target.value);
  };

  const handleBlur = () => {
    if(nickname.length === 0){
      setNickName(userInfo.nickname);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'user_info'
          }),
          credentials: 'include'
        });
        const data = await response.json();
        setUserInfo(data.Response);
        console.log(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      console.log(userInfo)
      setNickName(userInfo.nickname);
      // セッションストレージにニックネームを保存
      sessionStorage.setItem('nickname', userInfo.nickname);
      sessionStorage.setItem('picture', userInfo.picture ?? 'shirokuma.png');
      sessionStorage.setItem('provider', userInfo.providerName);
      sessionStorage.setItem('x_flag', userInfo.x_flag);
    }
  }, [userInfo]);

  const editImagePath = (imagePath) => {
      if(imagePath){
        const firstFourChars = imagePath.substring(0, 4);
        if (firstFourChars === "http") {
          return imagePath;
        } else {
          switch (imagePath) {
            case 'Shirokuma':
              return Shirokuma;
            case 'Kuma':
              return Kuma;
            case 'Kitsune':
              return Kitsune;
            case 'Saru':
              return Osaru;
            default:
              return Shirokuma;
          }
        }
      }else{
        return Shirokuma;
      }
  };

  const handleBackButtonClick = () => {
    setIsNickName(false)
  };

  const editImageButton = () => {
    navigateToEditImage();
  };

    return (
      <body>
        <img src={backgroundImage} class="side-image-left" alt="Side Image"></img>
        <img src={backgroundImage} class="side-image-right" alt="Side Image"></img>
        {userInfo && (
        <div className='account-page'>
          <Box
            component="img"
            sx={{
            height: 100,
            width: 100,
            borderRadius: '50%',
            marginTop: '3.5vh',
            boxShadow: '8px 5px 6px 6px rgba(210, 210, 210, 0.8)'
            }}
            alt="The house from the offer."
            src={editImagePath(sessionStorage.getItem('picture'))}
            //src={TestImage}
            onClick={editImageButton}
          />

          <div className='name-text'>
            <label className='editable-input' style={{display:'flex', justifyContent:'start', fontSize:'20px', marginLeft:'30px' ,color:'rgba(70, 70, 70, 1.0)'}}>
              {sessionStorage.getItem('nickname')}
            </label>
            <IconButton onClick={() => setIsNickName(true)}>
              <ModeIcon style={{color:'#477b5c', fontSize:'23px', marginBottom:'2px'}}/>
            </IconButton>
          </div>

          <div className='list-container'>
            {sessionStorage.getItem('provider') === 'Cognito' && (
              <ListItem button onClick={() => navigateToMailEdit()}  style={{paddingTop: '15px', paddingBottom: '10px'}}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText style={{textAlign: 'left'}} primary="メールアドレス変更" />
                <IconButton edge="end" aria-label="chevron">
                  <ChevronRightIcon style={{color:'#477b5c'}}/>
                </IconButton>
              </ListItem>
            )}
            {sessionStorage.getItem('provider') === 'Cognito' && (
              <Divider style={{height:'0.01px', backgroundColor:'#b5b5b5'}}/>
            )}
            {sessionStorage.getItem('provider') === 'Cognito' && (
              <ListItem button onClick={() => navigateToPasswordEdit()} style={{paddingTop: '15px', paddingBottom: '10px',}}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <KeyIcon />
                </ListItemIcon>
                <ListItemText primary="パスワード変更" />
                <IconButton edge="end" aria-label="chevron">
                  <ChevronRightIcon style={{color:'#477b5c'}} />
                </IconButton>
              </ListItem>
            )}
            {sessionStorage.getItem('provider') === 'Cognito' && (
              <Divider style={{height:'0.01px', backgroundColor:'#b5b5b5'}}/>
            )}
            <ListItem button onClick={() => navigateToProfileEdit()} style={{paddingTop: '15px', paddingBottom: '10px',}}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="プロフィール" />
              <IconButton edge="end" aria-label="chevron">
                <ChevronRightIcon style={{color:'#477b5c'}} />
              </IconButton>
            </ListItem>
            <Divider style={{height:'0.01px', backgroundColor:'#b5b5b5'}}/>
            <ListItem button onClick={() => navigateToXIDAliginment()} style={{paddingTop: '15px', paddingBottom: '10px',}}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <FolderSharedIcon />
              </ListItemIcon>
              <ListItemText 
                primaryTypographyProps={{ fontSize: '14px' }}
                primary="マイナンバーカード連携" />
              <IconButton edge="end" aria-label="chevron">
                <ChevronRightIcon style={{color:'#477b5c'}}/>
              </IconButton>
            </ListItem>
            <Divider style={{height:'0.01px', backgroundColor:'#b5b5b5'}}/>
            <ListItem style={{paddingTop: '15px', paddingBottom: '10px',}}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText 
                primaryTypographyProps={{ fontSize: '14px' }}
                primary="連携サービス管理" />
              <IconButton edge="end" aria-label="chevron">
                <ChevronRightIcon style={{color:'#477b5c'}}/>
              </IconButton>
            </ListItem>
            <Divider style={{height:'0.01px', backgroundColor:'#b5b5b5'}}/>
            <ListItem button onClick={() => navigateToDelete()} style={{paddingTop: '15px', paddingBottom: '10px',}}>
              <ListItemIcon sx={{ minWidth: 40 }}>
              <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary="アカウント削除" />
              <IconButton edge="end" aria-label="chevron">
                <ChevronRightIcon style={{color:'#477b5c'}} />
              </IconButton>
            </ListItem>
          </div>
          {isLoading && (
            <div className="loading-overlay">
              <h2>ユーザ情報取得中です。しばらくお待ちください。</h2>
              <div className="loader"></div>
            </div>
          )}
          {isNickName && (
            <div className="loading-overlay-nickname">
              <NickNamePage onBackButtonClick={handleBackButtonClick} userName={nickname}/>
            </div>
          )}
          {!isNickName && (
            <footer className='account' style={{zIndex: '-1'}}>
              <img src={footerImage} alt="The house from the offer" className="responsive-image" />
              <div className='footer-text'>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
              </div>
            </footer>
          )}
          
        </div>
        )}
      </body>
      );
};

export default AccountPage;