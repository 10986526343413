import React, { useState, useEffect } from 'react';
import './AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles, inputStylesMail } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';

export default function NickNamePage({onBackButtonClick, userName}){

  const [currentNickName, setCurrentNickName] = useState('');
  const [newNickName, setNewNickName] = useState('');
  const [newEmailError, setNewEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  const editNickName = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            userAttributes: [
              { Name: "nickname", Value: newNickName},
            ],
            pattern:'regist_info'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('ユーザ登録成功', responseData);
          // セッションストレージにニックネームを保存
          sessionStorage.setItem('nickname', newNickName);
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData.error);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
        onBackButtonClick();
      }
    }, 50);
  }

  useEffect(() => {
    setCurrentNickName(userName);
  }, []);

  useEffect(()=> {
    if(newNickName.length > 0){
      setIsButtonDisabled(false);
    }else{
      setIsButtonDisabled(true);
    }
  },[newNickName])

  return (
    <div className='account-page'>
      {!isComplete && (
        <div className='list-container-nickname'>
          <label htmlFor="email" 
            style={{display:'flex', justifyContent:'center', paddingTop:'0px' ,marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'16px', color:'rgba(30, 30, 30, 1.0)'}}>
              ニックネーム変更
          </label>
          <label htmlFor="email" 
            style={{display:'flex', justifyContent:'start', paddingTop:'10px' ,marginTop: '20px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(30, 30, 30, 1.0)'}}>
              現在のニックネーム
          </label>
          <TextField
            type="text"
            id="oldNick"
            value={currentNickName}
            fullWidth
            disabled
            slotProps={{
              input: {
                sx: inputStylesMail
              },
             }}
          />
          <label htmlFor="email" 
            style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
              変更後ニックネーム
          </label>
          <TextField
            type="text"
            id="newNick"
            value={newNickName}
            onChange={(e) => setNewNickName(e.target.value)}
            placeholder="新しいニックネーム"
            fullWidth
            error={newEmailError}
            slotProps={{
              input: {
                sx: inputStyles
              },
            }}
            style={{marginBottom:'30px'}}
          />
          <Button className="change" onClick={editNickName} disabled={isButtonDisabled}>変更する</Button>
          <Button className="back" onClick={onBackButtonClick} style={{marginBottom: '50px'}}>戻る</Button>
        </div>
      )}
      {isLoading && (
        <div className="loading-overlay">
          <h2>ユーザー情報を変更中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
    </div>  
  );
};