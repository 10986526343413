import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import './ResendCodeConfirm.css';  // スタイル用のCSSファイルを作成する
import ProgressSteps from '../../../componets/ProgressSteps.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { inputStyles, inputStylesMail  } from '../../../componets/TextField.jsx'
import { ResendCodeConfirmButton } from './component/ResendCodeConfirmButton.jsx'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';

function ResendConfirmPage(){
  const location = useLocation();
  const { email } = location.state || {};
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  const navigate = useNavigate();

  //emailが空だったらログインホームに戻す（/resendconfirmに直接アクセスされないように）
  useEffect(() => {
    if (!email) {
      navigate('/oauth2/authorize');
    }
  }, [email, navigate]);

  //登録ボタンが押されたときにコンポーネントを呼び出す
  const confirmButton = async () => {
    await ResendCodeConfirmButton(setIsLoading, email, code, setErrorMessage, setErrorCheck, navigate);
  };

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={1}/>
      <div className="login-container">
        <div className="form-container">
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <div style={{ width: '100%' }}>
              <label style={{ display: 'block', textAlign: 'start', marginTop: '15px', marginBottom: '0px' }}>下記メールアドレスに確認コードを再送信しました。送信された確認コードを入力してください。</label>
          </div>
          <label htmlFor="email" className="email">送信先メールアドレス</label>
          <TextField
              type="email"
              id="email"
              value={email}
              onChange={() => {}}
              fullWidth
              disabled
              slotProps={{
                input: {
                    sx: inputStylesMail
                },
               }}
          />
          <label htmlFor="password">認証コード</label>
          <TextField
              type="number"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
              slotProps={{
                input: {
                    sx: inputStyles
                },
              }}
          />
          <Link href="#" className='passforget' onClick={() => { /* 確認コードが届かない場合の画面を用意 */ }}>
              確認コードが届かない場合
          </Link>
          <Button className="loginButton" onClick={confirmButton}>次へ</Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>ログイン中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ResendConfirmPage;