//Googleサインインメソッド呼び出し
import { setOptoin } from '../../../../service/OptinService.js';
//クライアントによるリクエストログイン（アプリに戻すためのログイン）
import { requestLogin } from '../../../../service/RequestLogin.js';

import {getRandomImage} from '../../../../utils/random.js';

export const OptinButton = async (setIsLoading, setChecked, userInfo_flag, nickname, navigate) => {
    setIsLoading(true);
    setChecked(false);
    const iconImage = getRandomImage();
    //Optinの更新とUserInfoチェック
    const response = await setOptoin(iconImage);
    //更新が成功していたら以下処理を実行
    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
      if(userInfo_flag){
        requestLogin();
      }else if(!userInfo_flag){
        navigate('/regist', { replace: true, state: { nickname:nickname } } );
      }
    } else {
      const errorData = await response.json();
      console.error('オプトイン情報の更新に失敗しました。', errorData);
    }
};