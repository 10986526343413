//オプトイン更新
export const setLang = async () => {
    try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
            method: 'POST',
            headers: { 
            'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
            pattern:'langCookie'
            }),
            credentials: 'include'
        });

        return response;
        
    } catch (error) {
        return error;
    }
};