import React, { useState, useEffect } from 'react';
import '../AccountPage/EditInfoPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import {isKatakana, isNumeric} from '../../utils/validation.js'

function EditInfoPage(){
  
  const [userInfo, setUserInfo] = useState(null);
  const [family_name, setFamilyName] = useState('');
  const [given_name, setGivenName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [familyNameError, setFamilyNameError] = useState(false);
  const [givenNameError, setGivenNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
    setTimeout(() => {
      setIsFooterVisible(false);
    }, 350);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'user_info_profile'
          }),
          credentials: 'include'
        });
        const data = await response.json();
        setUserInfo(data.Response);
        console.log(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setFamilyName(userInfo.family_name);
      setGivenName(userInfo.given_name);
      setPhoneNumber(userInfo.customPhone);
    }
  }, [userInfo]);


  useEffect(() => {
    if(family_name.length === 0 || given_name.length === 0 || phone_number.length === 0){
      setIsButtonDisabled(true);
    }else if(family_name.length > 0 || given_name.length > 0 || phone_number.length > 0){
      if (familyNameError || givenNameError || phoneNumberError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    console.log(familyNameError, givenNameError, phoneNumberError, isButtonDisabled)
  }, [familyNameError, givenNameError, phoneNumberError, family_name, given_name, phone_number]);

  //入力されたフリガナ（姓）のチェック
  useEffect(() => {
    if(family_name.length > 0){
      setFamilyNameError(!isKatakana(family_name));
    }
  }, [family_name]);

  //入力されたフリガナ（名）のチェック
  useEffect(() => {
    if(given_name.length > 0){
      setGivenNameError(!isKatakana(given_name));
    }
  }, [given_name]);

  //入力されたフリガナ（名）のチェック
  useEffect(() => {
    if(phone_number.length > 0){
      setPhoneNumberError(!isNumeric(phone_number));
    }
  }, [phone_number]);


  const mailEdit = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            userAttributes: [
              { Name: "family_name", Value: family_name},
              { Name: "given_name", Value: given_name},
              { Name: "custom:phone", Value: phone_number}
            ],
            pattern:'regist_info'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('ユーザ登録成功', responseData);
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData.error);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }
  
    return (
      <div>
          {userInfo && (
            <div className='editinfo-page'>
              <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
                <label style={{fontSize: '14px', marginLeft:'8%'}}>ユーザ情報登録</label>
              </div>
              {!isComplete && (
                <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>プロフィール情報を登録してください</label>
                </div>
              )}
              {isComplete && (
                <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>ユーザ情報の登録が完了しました。</label>
                </div>
              )}
              {!isComplete && (
                <div className='list-container-editinfo'>
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      フリガナ（姓）
                  </label>
                  <TextField
                    type="text"
                    id="family_name"
                    value={family_name}
                    onChange={(e) => setFamilyName(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="ヤマダ"
                    fullWidth
                    error={familyNameError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                  />
                  {familyNameError && (
                    <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                      カタカナを入力してください
                    </FormHelperText>
                  )}
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '25px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      フリガナ（名）
                  </label>
                  <TextField
                    type="text"
                    id="given_name"
                    value={given_name}
                    onChange={(e) => setGivenName(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="タロウ"
                    fullWidth
                    error={givenNameError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                  />
                  {givenNameError && (
                    <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                      カタカナを入力してください
                    </FormHelperText>
                  )}
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '25px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      電話番号
                  </label>
                  <TextField
                    type="tel"
                    id="phone_number"
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="xxx-xxxx-xxxx"
                    fullWidth
                    error={phoneNumberError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                    style={{marginBottom:'35px'}}
                  />
                  {phoneNumberError && (
                    <FormHelperText error style={{whiteSpace: 'nowrap', marginBottom:'35px'}}>
                      数字のみ入力してください
                    </FormHelperText>
                  )}
                </div>
              )}
              {!isComplete && (
                <Button className="loginButton-change" onClick={mailEdit} disabled={isButtonDisabled}>変更する</Button>
              )}
              {!isComplete && (
                <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '100px'}}>戻る</Button>
              )}
              {isComplete && (
                <Button className="loginButton-back" onClick={navigateToAccount} style={{marginTop: '100px'}}>戻る</Button>
              )}
              {isLoading && (
                <div className="loading-overlay">
                  <h2>ユーザー情報を登録中です。しばらくお待ちください。</h2>
                  <div className="loader"></div>
                </div>
              )}
              {isFooterVisible && (
                <footer>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
                </footer> 
              )}
            </div>
          )}
      </div>
    );
};

export default EditInfoPage;