import React, { useState, useEffect } from 'react';
import './AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles, inputStylesMail } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import {isValidEmail} from '../../utils/validation.js'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';


import Shirokumasan from '../../assets/shirokuma.png';
import Kumasan from '../../assets/kuma.png';
import Kitsunesan from '../../assets/kitsune.png';
import Osarusan from '../../assets/saru.png';


function ImageEditPage(){

  const [mail, setMail] = useState('');
  const [newMail, setNewMail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  const testFlag = true;

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  const navigateToNextScreen = (user_id) => {
    console.log(user_id);
    navigate('/editmailconfirm' , { state: { email: newMail, id:user_id } } );
  };

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  const mailEdit = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            mail: mail,
            new_mail: newMail,
            pattern:'edit_mail'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('User registration successful', responseData);
          navigateToNextScreen(responseData.Response.userId);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'user_info_email'
          }),
          credentials: 'include'
        });
        const data = await response.json();
        setUserInfo(data.Response);
        console.log(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setMail(userInfo.email);
    }
  }, [userInfo]);

  //入力されたメールのチェック
  useEffect(() => {
    if(newMail.length > 0){
      setEmailError(!isValidEmail(newMail));
    }
  }, [newMail]);


  useEffect(() => {
    if(newMail.length === 0){
      setIsButtonDisabled(true);
    }else if(newMail.length > 0){
      if (emailError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    console.log(emailError,isButtonDisabled)
  }, [emailError, newMail]);


  const images = [
    Shirokumasan,
    Kumasan,
    Kitsunesan,
    Osarusan
  ];

  const [selectedImage, setSelectedImage] = useState(Shirokumasan);

  const [fileName, setFileName] = useState('');

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const cropToCircle = (img) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const size = Math.min(img.width, img.height);
    canvas.width = size;
    canvas.height = size;
  
    ctx.beginPath();
    ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
    ctx.clip();
    ctx.drawImage(img, (img.width - size) / 2, (img.height - size) / 2, size, size, 0, 0, size, size);
  
    return canvas.toDataURL();
  };
  

  const resizeAndCompressImage = (img, maxWidth, maxHeight, quality) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;
  
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
  
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
  
      // 画像をJPEG形式で圧縮
      const compressedImage = canvas.toDataURL('image/jpeg', quality);
      resolve(compressedImage);
    });
  };


  const handleFileInput = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = async () =>{
          const croppedImage = cropToCircle(img);
          const compressedImage = await resizeAndCompressImage(img, 800, 800, 0.7); 
          //これはS3に入れるため、Base64に変換
          const base64Image = compressedImage.split(',')[1];
          console.log(base64Image);
          setSelectedImage(compressedImage);

          const response = await fetch('https://dev-api.sendai-portal.jp/changeicon/spauth-dev-lambda-auth-changeicon', {
            method: 'POST',
            headers: { 
            'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              image:base64Image
            }),
            credentials: 'include'
          });
          if (response.ok) {
            const responseData = await response.json();
            console.log(responseData);
          } else {
            const errorData = await response.json();
            setErrorMessage(errorData.message);
            setErrorCheck(true);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  
    return (
        <div>
            <div className='account-page'>
              <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
                <label style={{fontSize: '14px', marginLeft:'8%'}}>アイコン変更</label>
              </div>
              <div className="single-image-wrapper" style={{  marginTop: '40px' , marginBottom: '50px' }}>
                <img src={selectedImage} alt="single-icon" className="image" />
              </div>
              <div className="container" style={{  marginBottom: '40px' }}>
                <div className="scroll-container">
                    {images.map((image, index) => (
                    <div key={index} className="image-wrapper" onClick={() => handleClick(image)}>
                        <img src={image} alt={`icon-${index}`} className="image" />
                    </div>
                    ))}
                    <div className="image-wrapper">
                    <input
                        type="file"
                        accept="image/*"
                        id="file"
                        className="file-input"
                        onChange={handleFileInput}
                    />
                    <label htmlFor="file" className="file-label" style={{  marginRight: '50px' }}>
                        <IconButton component="span" className="file-button">
                            <AddIcon className="plus-icon" style={{ fontSize: '110px', marginLeft: '10px' }} />
                        </IconButton>
                    </label>
                    </div>
                </div>
              </div>
              <Button className="loginButton-change" onClick={mailEdit} disabled={isButtonDisabled}>変更する</Button>
              <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '100px'}}>戻る</Button>
              {isLoading && (
                <div className="loading-overlay">
                  <h2>メールアドレスを変更中です。しばらくお待ちください。</h2>
                  <div className="loader"></div>
                </div>
              )}
              {isFooterVisible && (
                <footer>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
                </footer> 
              )}
            </div>
        </div>
      
    );
};

export default ImageEditPage;