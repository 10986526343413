import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './AccountPage.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//プロバイダーサインインメソッド呼び出し
import { xIDSignIn } from '../../service/SocialLoginService';

function XIDAliginmentPage(){
  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  //画面作成時に実行される
  useEffect(() => {
    const fetchToken = async () => {

      //URLを取得するための初期化
      const params = new URLSearchParams(location.search);
      //認可code取得
      const codeParam = params.get('code');

      if (codeParam){  //認可コードがあれば以下実行
        setIsLoading(true);
        try {
          const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
              method: 'POST',
              headers: { 
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${codeParam}`
              },
              body: JSON.stringify({
                redirect_uri: 'https://dev-login.sendai-portal.jp/xidaliginment',
                pattern:'xid'
              }),
              credentials: 'include'
            });
          //オプトインのリクエストに成功した場合
          if (response.ok) {
            const responseData = await response.json();
            sessionStorage.setItem('x_flag', '1');
            console.log(responseData);
          } else {
            const errorData = await response.json();
            console.error('Failed to register user:', errorData);
          }
        } catch (error) {
          console.error('Error during registration:', error);
        } finally{
          setIsLoading(false);
        }
      }
    };

    //userEfect内で非同期処理を実行
    fetchToken();

  }, [location]);

  async function xIDAliginmentButton(){
    sessionStorage.setItem('provider', 'xID');
    xIDSignIn('xID');



    // const clientId = '7bb7bb56-ec41-444d-8494-132d59739468';
    // const responseType = 'code';
    // const scope = 'openid verification reverification';
    // const redirectUri = 'https://dev-login.sendai-portal.jp/xidaliginment';
    // const state = 'H4sIAAAAAAAAAFWRXW-bMBSG_4uvA8UQPi9JaRPakiVpRbJpQsfYgFewCQbSZdp_n9NpF7s6j_zKr46e8wsBihD0hpDD2DBQo4EL6_HhvElwfUULRHR8gkZKzaXm1iUNJhCUI5GStHNgTx51QtvWOdV5KTuTfUDXt8xUTFDgM2eX6O5O5-wzp0xjpXG7uV9prFH0DcFFmaWsBR-lqbiewpwUG0ygHRe3rx3wVk_ZM8Gphr6R4lbUD7LiLUPfF-hddwb2QSXJc5rlKSbOvHOgXBH5tU_zTWVdtj3Gufsz2cMh2K_p04vtr2f27NMdL7oW95Bma9fI0nC0VqfgmuCUq93HWYXx_BJ3_RKmSzw1p7F4bZogeFu-x1-KbF8cyzpQWYJLdxADr2oOx-DHXmFavz7N1fmQe2v3YX6sjvmOzmK6LxuyjZVevtUb_5Vk9No_tAZMY2NQNps3MP-_iwkdXKX4p0mb1hUdirDvYHfpY8fROlBUQavYAg03Gx4JwLcsw6oqbCyJbRkEPNsIw4pazPI8_YR-_wH87Jv8BAIAAA.H4sIAAAAAAAAAAEgAN__qHSEsZSRQKGU6rQLoVcLsCfrxMdQLTksE7Q2jp6dBZFluXT-IAAAAA.3';//クライアント側が指定
    // const nonce = 'idtokendesuyonekorewa';
    // // const codeChallenge = challenge;
    // // const codeChallengeMethod = 'S256';

    // const authUrl = `https://oidc-uat.x-id.io/oauth2/auth?` +
    //   `client_id=${clientId}&` +
    //   `response_type=${responseType}&` +
    //   `scope=${encodeURIComponent(scope)}&` +
    //   `redirect_uri=${encodeURIComponent(redirectUri)}&` +
    //   `response_mode=query&` +
    //   `state=${state}&` +
    //   `nonce=${nonce}&`
    //   // `code_challenge=${codeChallenge}&` +
    //   // `code_challenge_method=${codeChallengeMethod}`;

    // //Auth0のログイン画面を表示
    // window.location.href = authUrl;
  }
  
  return (
    <div className='account-page'>
      <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
        <label style={{fontSize: '14px', marginLeft:'8%'}}>マイナンバーカード連携</label>
      </div>
      {sessionStorage.getItem('x_flag') === '1' && (
        <div className='list-container-text' style={{ justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'14px', paddingTop:'20px', paddingBottom: '30px'}}>
          <label style={{fontSize: '16px', marginTop:'10px', marginBottom:'20px', display: 'block'}}> </label>
          <p>マイナンバーカードは連携済みです。</p>
          <label style={{fontSize: '16px', marginBottom:'20px', display: 'block'}}> </label>
        </div>
      )}
      {sessionStorage.getItem('x_flag') === '0' && (
        <div className='list-container-text' style={{ justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'14px', paddingTop:'20px', paddingBottom: '30px'}}>
          <label style={{fontSize: '16px', fontWeight: '700', marginTop:'10px', marginBottom:'20px', display: 'block'}}>マイナンバーカード連携について</label>
          <p>個人情報を入力する必要がなくなり～。</p>
          <label style={{fontSize: '16px', fontWeight: '400', marginTop:'30px', marginBottom:'150px', display: 'block'}}>一時的に連携を解除するには</label>
        </div>
      )}
      {sessionStorage.getItem('x_flag') === '0' && (
        <Button className="loginButton-aliginment" onClick={xIDAliginmentButton}>連携する</Button>
      )}
      <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '50px'}}>戻る</Button>
      {isLoading && (
        <div className="loading-overlay">
          <h2>xID連携中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default XIDAliginmentPage;