//emailチェック
export const isValidEmail = (value) => {
    if (!value) return false;
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegExp.test(value);
};
//パスワードチェック（大文字・小文字・記号の10文字以上）
export const isValidPassword = (value) => {
    const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$_%^&*()])[\w!@#$%^&*()]{10,}$/;
    return value && passwordRegExp.test(value);
};
//パスワード＝＝パスワード再入力チェック
export const isConfirmPassword = (text, password) => {
    return text === password;
};

//カタカナのみOKにするチェック
export const isKatakana = (value) => {
    const katakanaRegExp = /^[\u30A0-\u30FF]+$/;
    return value && katakanaRegExp.test(value);
};

//数字のみOKにするチェック
export const isNumeric = (value) => {
    const numericRegExp = /^\d+$/;
    return value && numericRegExp.test(value);
};